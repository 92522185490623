// zeditor custom elements
.zeditor-register{
    background-color: red !important;
    padding: 8px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    p{
        margin: 0px !important;
        padding: 0px !important;
        color: #ffffff;
    }
}