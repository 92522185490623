@import '_variables.scss';

html, body{
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
}

#root{
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
}