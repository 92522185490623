@import '_variables.scss';

.div-flex-row{
    display: flex;
}

.fullpage{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullpage-content{
    text-align: center;
}

// forms
.fields{
    padding-right: 16px;
}

.form-item{
    margin-bottom: 16px !important;
    width: 100%;
}

// blocks
.container{
    padding: 16px;
    height: calc(100% - 96px);
}

.row-control{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.row-control-down{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    button{
        margin-left: 16px;
    }
}

.selected-file{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey;
    margin-bottom: 16px;
    img{
        max-width: 100%;
        max-height: 100%;
        display: block;
    }
}
.selected-input{
    input{
        margin-bottom: 16px;
    }
}