@import '_variables.scss';

.appbar-container{
    flex-grow: 1;
    .appbar-title{
        flex: 1;
    }
    .appbar-btn{
        margin-right: 16px;
    }
}

.DraftEditor-root{
    padding: 0px 16px;
    border: 1px solid $grey;
    min-height: 300px;
}